@use '@angular/material' as mat;
@use './theme';

@include mat.core();

// Define a light theme
$light-primary: mat.define-palette(theme.$md-primary);
$light-accent: mat.define-palette(theme.$md-accent);
$light-warn: mat.define-palette(theme.$md-warn);
$light-success: mat.define-palette(theme.$md-success);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
      success: $light-success,
    ),
  )
);

// Define a dark theme
/* $dark-primary: mat.define-palette($md-myprimary);
$dark-accent: mat.define-palette($md-myaccent);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary,
      accent: $dark-accent,
    ),
  )
); */

// Apply the light theme by default
@include mat.core-theme($light-theme);
@include mat.all-component-themes($light-theme);

// Apply the dark theme only when the `.my-dark-theme` CSS class is applied
// to an ancestor element of the components (such as `body`).
/* .dark-theme {
  @include mat.core-color($dark-theme);
  @include mat.all-component-themes($dark-theme);
} */

body {
  margin: 0px;
  padding: 0px;
}

main {
  height: calc(100vh - 124px);
  padding: 16px;
  overflow: auto;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-drawer {
  top: unset !important;
  bottom: unset !important;
  display: unset !important;
}

.cdk-overlay-container {
  z-index: 99999999999999999999999999999999999999 !important;
}

.arrow-back {
  mat-icon {
    padding-left: 6px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.progress-bar-grid {
  height: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif !important;
}

p,
span,
input {
  font-family: 'Merriweather', sans-serif !important;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: black;
  font-family: 'Merriweather', sans-serif !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

mat-header-cell > span {
  font-size: 12px !important;
}

.mat-form-field-flex {
  padding: 4px 0 0 4px !important;
  background: rgba(theme.$primary300, 0.087) !important;
}
.mat-form-field-infix {
  width: unset;
}
.mat-header-cell {
  font-weight: bold !important;
}
.mat-row:hover {
  background-color: rgba(theme.$primary300, 0.087);
  cursor: pointer;
}
.mat-form-field-subscript-wrapper {
  padding-left: 0 !important;
}
.mat-list-base {
  padding-top: unset !important;
}
.loading-bar-container {
  height: 4px;
  .mat-progress-bar-buffer {
    background-color: theme.$accent500;
  }
}
.mat-dialog-container {
  padding: 0 0 24px !important;
}

mat-tab-header {
  margin-bottom: 16px !important;
}
mat-tab-body {
  padding: 0 16px 16px 16px !important;
}

.filters {
  .mat-form-field-flex {
    background: rgba(77, 108, 156, 0.087) !important;
  }
}

.div1 {
  .mat-form-field-flex {
    background: none !important;
  }
  .mat-select-arrow-wrapper {
    transform: none !important;
  }
}
